<template>
  <div class="pb-2">
    <PortalTarget name="tab-title" />
    <!--    <catalog-tabs />-->
    <router-view />
  </div>
</template>

<script>

import CatalogTabs from '@/views/main/catalog/components/CatalogTabs.vue'
import { ref } from '@vue/composition-api'

export default {
  name: 'CatalogList',
  components: { CatalogTabs },
  setup() {
    const activeComponent = ref('')
    return {
      activeComponent,
    }
  },
}
</script>
