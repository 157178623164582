<template>
  <div class="my-1">

    <b-button
      v-for="productType in Object.keys(items)"
      :key="productType"
      class="btn category-button-tabs"
      :variant="getVariant(productType)"
      @click="gotoNewPage({name: items[productType].route }, $event)"
    >
      {{ items[productType].label }}
    </b-button>
  </div>
</template>
<script>
import {
  BButton,
} from 'bootstrap-vue'
import store from '@/store'

export default {
  name: 'CatalogTabs',
  components: {
    BButton,
  },
  computed: {
  },
  methods: {
    getVariant(productType) {
      return productType === this.$parent.activeComponent ? 'secondary' : 'light'
    },
  },
  setup() {
    const {
      productStocks,
      productTypeKits,
      productNonStocks,
    } = store.state.listModule
    const items = {
      [productStocks]: {
        label: 'Stock',
        route: 'home-main-catalog-list-stocks',
      },
      [productTypeKits]: {
        label: 'Kits',
        route: 'home-main-catalog-list-kits',
      },
      [productNonStocks]: {
        label: 'Non-Stock',
        route: 'home-main-catalog-list-non-stocks',
      },

    }
    return {
      items,
    }
  },
}

</script>
<style scoped>
  .category-button-tabs {
    margin-right: 4px;
  }
</style>
